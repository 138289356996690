import {Component,ChangeDetectorRef,ViewChild,AfterViewInit,OnInit,Self,ViewEncapsulation} from '@angular/core';
import { formatDate } from '@angular/common';
import { AltProvResultDate } from '@reports/models/altprov-result-date';
import { FilterMetadata,FilterMatchMode, LazyLoadEvent } from 'primeng/api';
import { Table, TableService } from 'primeng/table';
import { BaseView } from '@shared/components/Base/base-view';
import { MatDialog } from '@angular/material/dialog';
import { AltProviderService } from '@shared/services/altprovider-service/altprovider.service';
import { AltProvResult } from '@reports/models/altprov-result';
import { AltProvResultsService } from '@reports/services/altprov-results.service';
import { FilterService } from '@shared/services/filter-service/filter.service';
import { QuerySessionManagerService } from '@shared/services/query-session-manager-service/query-session-manager.service';
import { HttpErrorResponse } from '@angular/common/http';

import { PccExceptionEntryComponent } from '@pccExceptions/components/pcc-exception-entry/pcc-exception-entry.component';
import { MemberExceptionDetailComponent } from '@memberExceptions/components/member-exception-detail/member-exception-detail.component';
import { ProductsService } from 'src/app/products/services/products.service';
import { ColumnDefinition } from '@shared/models/column-definition';
import { forkJoin } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { exportDataToExcel } from '@shared/util/excel-exporter';
import { AltProvAssignment } from '@reports/models/altprov-assignment';
import { ActivatedRoute } from '@angular/router';

import {
  BROWSER_STORAGE,
  ClearFilterService
} from '@shared/services/clear-filter-service/clear-filter.service';
import { MatOptionSelectionChange } from '@angular/material/core';




@Component({
  selector: 'app-altprov-results-list',
  templateUrl: './altprov-results-list.component.html',
  styleUrls: ['./altprov-results-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ClearFilterService, { provide: BROWSER_STORAGE, useFactory: () => localStorage }]
})
export class AltProvResultsListComponent extends BaseView implements OnInit, AfterViewInit {
  public readonly querySessionManager: QuerySessionManagerService;

  @ViewChild(Table) table!: Table;
  
  _array = Array;

  selectedColumns: ColumnDefinition[] = [];
  allColumns: ColumnDefinition[] = [];
  first = 0;

  pagingSizes = [20, 40, 60, 80, 100];

  loadingTable = true;
  altProvResults: AltProvResult[] = [];
  altProvResultsDateOptions: Array<AltProvResultDate> = [];
  selectedDate = '';
  totalItems = 0;
  ruleNumberFromRoute!: any;

  private previousLoadEvent: LazyLoadEvent | undefined = undefined;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly filterService: FilterService,
    @Self() private readonly clearFilterService: ClearFilterService,
    private readonly productsService: ProductsService,    
    private readonly dialog: MatDialog,    
    private readonly alternateProvidersService: AltProviderService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly altProvResultsService: AltProvResultsService
  ) {
    super();

    this.initColumnDefinitions();

    this.querySessionManager = new QuerySessionManagerService('altprov-results-list');

    // Clear any cached sessions on page load
    this.querySessionManager.resetSession();
  }

  ngOnInit(): void {
    this.ruleNumberFromRoute = this.activatedRoute.snapshot.paramMap.get('ruleNumber');

    forkJoin([
      this.alternateProvidersService.getAlternateProviders(),
      this.productsService.getAllProducts(),
      this.altProvResultsService.getAltProvResultDates()
    ])
      .pipe(
        tap(([altProvs, products, dates]) => {
          const altProvOptions = altProvs.filter(x => x.active).map(y => y.alternateProviderName);
          const productOptions = products.filter(x => x.active).map(y => y.productName ?? '');

          this.altProvResultsDateOptions = dates;
          this.altProvResultsDateOptions.push({
            type: ' -  -  Current Records Only',
            id: '',
            completionTime: '9999-12-31T00:00:00'
          } as AltProvResultDate);

          this.altProvResultsDateOptions = dates.sort((x, y) =>
            x.completionTime > y.completionTime ? -1 : y.completionTime > x.completionTime ? 1 : 0
          );

          this.setColumnOptions('altProv', altProvOptions);
          this.setColumnOptions('product', productOptions);
          this.setColumnOptions('assignedBy', ['Rule', 'PCC Exception', 'Member Exception']);

          if (this.ruleNumberFromRoute) {
            this.querySessionManager.resetSession();
            this.selectedDate = '9999-12-31T00:00:00';
          }
        }),
        take(1)
      )
      .subscribe();
  }

  ngAfterViewInit(): void {  
    if(this.table != null)  {
      const columnChangeSub = this.table.tableService.columnsSource$.subscribe(() => {
        this.table.saveState();    
      });
      this.subs.add(columnChangeSub);
    }       
  }

  async loadAltProvResultsLazy(event: LazyLoadEvent): Promise<void> {
    this.loadingTable = true;
    this.first = event.first ?? 0;

    // Blow away the query session every time unless we are paging forward/backward
    if (this.filterService.didChangePage(this.previousLoadEvent, event) === false) {
      this.querySessionManager.resetSession();
    }

    const clonedEvent = Object.assign({}, event);

    if (+this.ruleNumberFromRoute > 0) {
      // tslint:disable-next-line: no-non-null-assertion no-string-literal
      clonedEvent.filters!['ruleNumber'] = {
        matchMode: 'arrayContains',
        value: [+this.ruleNumberFromRoute, 'number']
      } as FilterMetadata;
    }

    // tslint:disable-next-line: forin
    for (const filterKey in clonedEvent.filters) {
      const selectedColumn = this.selectedColumns.find(x => x.field === filterKey);

      if (!selectedColumn) {
        delete clonedEvent.filters[filterKey];
      } else {
        if (selectedColumn.filterMatchMode) {
          clonedEvent.filters[filterKey].matchMode = selectedColumn.filterMatchMode;

          if (
            selectedColumn.arrayDisplayValue &&
            selectedColumn.filterMatchMode === 'arrayContains'
          ) {
            if (selectedColumn.options) {
              clonedEvent.filters[filterKey].value = clonedEvent.filters[filterKey].value?.filter(
                (x: string) => x !== selectedColumn.arrayDisplayValue
              );

              if (clonedEvent.filters[filterKey].value?.length > 0) {
                clonedEvent.filters[filterKey].value.push(selectedColumn.arrayDisplayValue);
              }
            } else {
              if (clonedEvent.filters[filterKey].value) {
                const isArray = Array.isArray(clonedEvent.filters[filterKey].value);

                clonedEvent.filters[filterKey].value = [
                  isArray
                    ? clonedEvent.filters[filterKey].value[0]
                    : clonedEvent.filters[filterKey].value,
                  selectedColumn.field === 'ruleNumber' ||
                  selectedColumn.field === 'pccExceptionNumber' ||
                  selectedColumn.field === 'memberExceptionNumber'
                    ? 'number' // These fields are an exception case. Typically, the db column will match the arrayDisplayValue
                    : selectedColumn.arrayDisplayValue
                ];
              }
            }
          }
        }
      }
    }

    if (this.selectedDate !== '9999-12-31T00:00:00') {
      // tslint:disable-next-line: no-non-null-assertion
      clonedEvent.filters!.versionEnd = {
        matchMode: 'dateBefore',
        value: this.selectedDate
      } as FilterMetadata;
    } else {
      // tslint:disable-next-line: no-non-null-assertion
      clonedEvent.filters!.versionEnd = {
        matchMode: 'dateIs',
        value: this.selectedDate
      } as FilterMetadata;
    }

    const fetchSub = this.altProvResultsService
      .getAllAltProvResults(clonedEvent, this.querySessionManager.currentSession)
      .subscribe(
        response => {
          this.altProvResults = this.mapResults(response.results);
          this.totalItems = response.totalResults;

          if (response.querySessionId) {
            this.querySessionManager.currentSession = response.querySessionId;
          }
        },
        (error: HttpErrorResponse) => {
          // If our query session ID was deleted,
          // clear it out and try again
          if (error.status === 404) {
            this.querySessionManager.resetSession();
            this.table._filter();
          }
        },
        () => {
          this.loadingTable = false;

          fetchSub.unsubscribe();
        }
      );

    this.previousLoadEvent = event;
  }

  getRoute(field: string, index: number): Array<string> {
    switch (field) {
      case 'memberId':
        return ['members', this.altProvResults[index].memberId];
      case 'ruleNumber':
        return ['rules', 'edit', this.altProvResults[index].altProv[0].number];
      case 'pccExceptionNumber':
        return ['pccExceptions', this.altProvResults[index].altProv[0].number];
      case 'memberExceptionNumber':        
        return ['memberExceptions', this.altProvResults[index].altProv[0].number];
      default: {
        return [];
      }
    }
  }

 
  onDateSelectionChange(e: MatOptionSelectionChange): void {
    this.selectedDate = e.source.value;

    if (this.selectedDate) {
      const newEvent: LazyLoadEvent = {
        first: 0,
        rows: 20,
        filters: {
          ['versionEnd']: { matchMode: 'dateBefore', value: this.selectedDate }
        }
      };

      this.loadAltProvResultsLazy(newEvent);
    }
  }

  getLocalDate(dateString: string): string {
    if (dateString === '9999-12-31T00:00:00') {
      return '';
    }

    return formatDate(dateString, 'short', 'en-US');
  }

  exportExcel(): void {
    const excelData: any[] = this.formatExcelData();

    exportDataToExcel(excelData, 'AltProvResults');
  }

  private mapResults(response: any): Array<AltProvResult> {
    const results = response;

    results.forEach((result: AltProvResult) => {
      result.assignedBy = [];
      result.altProv_id = [];
      result.ruleNumber = [];
      result.memberExceptionNumber = [];
      result.pccExceptionNumber = [];

      result.altProv.forEach((altProv: AltProvAssignment) => {
        result.assignedBy.push({ Type: altProv.Type });
        result.altProv_id.push(altProv.Id);

        switch (altProv.Type) {
          case 'Rule':
            if (!result.ruleNumber) {
              result.ruleNumber = [];
            }
            result.ruleNumber.push({ ruleNumber: altProv.number });
            break;
          case 'Member Exception':
            if (!result.memberExceptionNumber) {
              result.memberExceptionNumber = [];
            }
            result.memberExceptionNumber.push({
              memberExceptionNumber: altProv.number
            });
            break;
          case 'PCC Exception':
            if (!result.pccExceptionNumber) {
              result.pccExceptionNumber = [];
            }
            result.pccExceptionNumber.push({ pccExceptionNumber: altProv.number });
            break;
          default:
            console.log(`Unknown altProv assignment type: ${altProv.Type}`);
            break;
        }
      });
    });

    return results;
  }

  private formatExcelData(): Array<any> {
    return this.altProvResults
      .map(x => ({
        memberId: x.memberId,
        memberNumber: x.memberNumber,
        amisysId: x.amisysId,
        ruleNumber: x.ruleNumber.map(y => y.ruleNumber).toString(),
        pccExceptionNumber: x.pccExceptionNumber.map(y => y.pccExceptionNumber).toString(),
        memberExceptionNumber: x.memberExceptionNumber.map(y => y.memberExceptionNumber).toString(),
        assignedBy: x.assignedBy,
        product: x.product,
        altProv: x.altProv,
        region: x.region,
        healthstat: x.healthstat,
        pccId: x.pccId,
        auto_assign: x.auto_assign,
        benefit_pkg: x.benefit_pkg,
        class_x: x.class_x,
        group_nbr: x.group_nbr,
        hat_code: x.hat_code,
        insurance: x.insurance,
        maritalstat: x.maritalstat,
        reason: x.reason,
        void: x.void,
        covgBegin: x.covgBegin,
        covgEnd: x.covgEnd
      }))
      .sort((x, y) => +x.memberId - +y.memberId);
  }

  onStateRestore(event: any): void {
    const columnKeys = event.columnOrder;
    const columns = this.getFilterByKeyOrdered(columnKeys);

    this.selectedColumns = columns;
    this.cd.detectChanges();
  }

  onColumnReorder(): void {
    this.table.saveState();
  }

  private getFilterByKeyOrdered(keys: string[]): Array<ColumnDefinition> {
    const result: any[] = [];

    for (const key of keys) {
      const column = this.allColumns.find(f => f.field === key);
      result.push(column);
    }

    return result;
  }

  private initColumnDefinitions(): void {
    const defaultColumns: ColumnDefinition[] = [
      {
        field: 'memberId',
        shortName: 'Member Id',
        longName: 'Member Id',
        type: 'text',
        routing: true,
        static: true
      },
      {
        field: 'memberNumber',
        shortName: 'Member Number',
        longName: 'Member Number',
        type: 'text',
        static: true
      },
      {
        field: 'amisysId',
        shortName: 'Amisys Id',
        longName: 'Amisys Id',
        type: 'text',
        static: true
      },
      {
        field: 'assignedBy',
        shortName: 'Assigned By',
        longName: 'Assigned By',
        type: 'text',
        options: [],
        arrayDisplayValue: 'Type',
        filterMatchMode: 'arrayContains',
        static: true
      },
      {
        field: 'ruleNumber',
        shortName: 'Rule ID',
        longName: 'Rule ID',
        type: 'text',
        arrayDisplayValue: 'ruleNumber',
        filterMatchMode: 'arrayContains',
        routing: true
      },
      {
        field: 'pccExceptionNumber',
        shortName: 'PCC Exception Id',
        longName: 'PCC Exception Id',
        type: 'text',
        arrayDisplayValue: 'pccExceptionNumber',
        filterMatchMode: 'arrayContains',
        routing: true
      },
      {
        field: 'memberExceptionNumber',
        shortName: 'Member Exception Id',
        longName: 'Member Exception Id',
        type: 'text',
        arrayDisplayValue: 'memberExceptionNumber',
        filterMatchMode: 'arrayContains',
        routing: true
      },
      {
        field: 'product',
        shortName: 'Product',
        longName: 'Product',
        type: 'text',
        filterMatchMode: 'in',
        options: []
      },
      {
        field: 'altProv',
        shortName: 'Alternate Provider',
        longName: 'Alternate Provider',
        type: 'text',
        arrayDisplayValue: 'alternateProviderName',
        options: [],
        filterMatchMode: 'arrayContains',
        static: true
      }
    ];

    const additionalColumns: ColumnDefinition[] = [
      { field: 'region', shortName: 'Region', longName: 'Region', type: 'text' },
      { field: 'county', shortName: 'County', longName: 'County', type: 'text' },
      {
        field: 'maritalstat',
        shortName: 'Marital Status',
        longName: 'Marital Status',
        type: 'text'
      },
      { field: 'healthstat', shortName: 'Health Status', longName: 'Health status', type: 'text' },
      { field: 'pccId', shortName: 'PCC Id', longName: 'PCC Id', type: 'text' },
      { field: 'pccName', shortName: 'PCC Name', longName: 'PCC Name', type: 'text' },
      { field: 'auto_assign', shortName: 'Auto Assign', longName: 'Auto Assign', type: 'text' },
      {
        field: 'benefit_pkg',
        shortName: 'Benefit Package',
        longName: 'Benefit Package',
        type: 'text'
      },
      { field: 'class_x', shortName: 'Class X', longName: 'Class X', type: 'text' },
      { field: 'group_nbr', shortName: 'Group Number', longName: 'Group Number', type: 'text' },
      { field: 'hat_code', shortName: 'Hat Code', longName: 'Hat Code', type: 'text' },
      { field: 'insurance', shortName: 'Insurance', longName: 'Insurance', type: 'text' },
      { field: 'reason', shortName: 'Reason', longName: 'Reason', type: 'text' },
      { field: 'void', shortName: 'Void', longName: 'Void', type: 'text' },
      {
        field: 'covgBegin',
        shortName: 'Coverage Begin Date',
        longName: 'Coverage Begin Date',
        type: 'text'
      },
      {
        field: 'covgEnd',
        shortName: 'Coverage End Date',
        longName: 'Coverage End Date',
        type: 'text'
      }
    ];

    this.selectedColumns = defaultColumns;
    this.allColumns = defaultColumns.concat(additionalColumns);
  }
  openMemberExceptionDetail(memberExceptionNumber: string) {
    let dialogRef = this.dialog.open(MemberExceptionDetailComponent, {
      autoFocus: true,
      width: '1000px',
      maxHeight: '900px',
      panelClass: 'custom-dialog-container',
      data: { memberExceptionId: memberExceptionNumber }
    });

    dialogRef.afterClosed().subscribe();
    this.cd.detectChanges();
  }
  openPCCExceptionDetail(pccExceptionNumber: string) {
    let dialogRef = this.dialog.open(PccExceptionEntryComponent, {
      autoFocus: true,
      width: '1000px',
      maxHeight: '900px',
      panelClass: 'custom-dialog-container',
      data: { pccExceptionId: pccExceptionNumber }
    });

    dialogRef.afterClosed().subscribe();
    this.cd.detectChanges();
  }
  private setColumnOptions(field: string, options: Array<any>): void {
    const selectedColumnsIndex = this.selectedColumns.findIndex(x => x.field === field);
    const allColumnsIndex = this.allColumns.findIndex(x => x.field === field);

    if (selectedColumnsIndex > 0) {
      this.selectedColumns[selectedColumnsIndex].options = options;
    }

    if (allColumnsIndex > 0) {
      this.allColumns[allColumnsIndex].options = options;
    }
  }

  public clearFilter(storageKey: string): void {
    this.clearFilterService.removeStorageFilterByKey(storageKey, this.table);
  }
}
