<mat-toolbar color="primary">
  <button
    *ngIf="loginDisplay"
    mat-icon-button
    (click)="navExpanded = !navExpanded"
    aria-label="Toggle navigation menu"
  >
    <mat-icon>menu</mat-icon>
  </button>

  <span class="toolbar-spacer"
    >Member Alternate Provider!
    <strong class="alt-ProvNew">*** New ***</strong>
  </span>
  <button mat-raised-button *ngIf="!loginDisplay" (click)="login()">Login</button>
  <span *ngIf="loginDisplay">Logged in as: {{ getName() }}&nbsp;</span>
  <button mat-raised-button *ngIf="loginDisplay" style="float: right" (click)="logout()">
    Logout
  </button>
</mat-toolbar>

<mat-sidenav-container class="sidenav-content-fill-height">
  <mat-sidenav *ngIf="loginDisplay" mode="side" [opened]="navExpanded">
    <p-panelMenu [model]="items" [style]="{ width: '300px' }"></p-panelMenu>
  </mat-sidenav>

  <mat-sidenav-content>
    <main>
      <router-outlet *ngIf="!isIframe"></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
